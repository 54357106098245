@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap");

footer {
  height: 150px;
  max-width: 2000px;
  width: 100%;
  padding: 2rem 0;
}

.footer__panel {
  padding: 10px;
  min-height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer__card > ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.footer__card > ul > label {
  font-size: 30px;
  color: rgb(26, 26, 26);
  font-weight: 700;
}

.footer__card > ul > a {
  position: relative;
  letter-spacing: 2px;
  margin-top: 5px;
  font-size: 20px;
  text-decoration: none;
  color: rgb(29, 29, 29);
}

.footer__card > ul > a:after {
  left: 0;
  top: 25px;
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.5px;
  background-color: #747474;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.footer__card > ul > a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.footer__logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__logo > img {

  padding-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  .footer {
    height: calc(100vh - 200px);

  }

  .footer__panel {
    flex-direction: column;
  }

  .footer__card {
    margin-top: 20px;
    width: 90vw;
  }

  .last {
    padding-bottom: 20px;
  }

  .footer__address {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
