@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@font-face {
    font-family: TruenoUltLt;
    src: url('./assets/TruenoUltLt.otf');
}

@font-face {
    font-family: Nothing;
    src: url('./assets/nothing.otf');
}

@font-face {
    font-family: queenserif;
    src: url('./assets/queenserif.otf');
}

@font-face {
    font-family: accent;
    src: url('./assets/accent.ttf');
}

@font-face {
    font-family: dgothic;
    src: url('./assets/dgothic.ttf');
}

@font-face {
    font-family: TruenoLt;
    src: url('./assets/TruenoLt.otf');
}

@font-face {
    font-family: TruenoBlk;
    src: url('./assets/TruenoBlk.otf');
}

@font-face {
    font-family: orsay;
    src: url('./assets/orsay.otf');
}

@font-face {
    font-family: BDB;
    src: url('./assets/BeautiqueDisplay-Bold.otf');
}

@font-face {
    font-family: BDBM;
    src: url('./assets/BeautiqueDisplay/BeautiqueDisplay-Light.otf');
}

@font-face {
    font-family: FKG;
    src: url('./assets/FKGroteskMono-Regular.otf');
}



@font-face {
    font-family: FuturaPTBook;
    src: url('./assets/FuturaCyrillicBook.ttf');
}

@font-face {
    font-family: FuturaPTDemi;
    src: url('./assets/FuturaCyrillicDemi.ttf');
}

@font-face {
    font-family: FuturaPTExBold;
    src: url('./assets/FuturaCyrillicExtraBold.ttf');
}

@font-face {
    font-family: FuturaPTBold;
    src: url('./assets/FuturaCyrillicBold.ttf');
}

@font-face {
    font-family: FuturaPTHeavy;
    src: url('./assets/FuturaCyrillicHeavy.ttf');
}

@font-face {
    font-family: FuturaPTMedium;
    src: url('./assets/FuturaCyrillicMedium.ttf');
}



@font-face {
    font-family: 'FuturaPTLight';
    src: url('./assets/FuturaCyrillicLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    background-color: #ececed;
}

.app {
    font-family: FuturaPTLight;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.layout {

    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}