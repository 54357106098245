.product__page {
    min-height: 100vh;
    min-width: 100vw;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.product__detail {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
}

.product__images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50vw;
    min-height: 85vh;
}

.product__mob>h2 {
    color: rgb(108, 108, 108);
}

.product__mob>h2>span {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
}


.product__images>img {
    height: 95vh;
    margin-bottom: 20px;
}

.product__info__left {

    color: rgb(59, 59, 59);
    display: flex;
    justify-content: center;
    align-items: start;
    background: rgba(255, 255, 255, 0.18);
    border-radius: 4px;
    box-shadow: 0 4px 30px rgba(109, 109, 109, 0.21);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    flex-direction: column;
    padding: 20px;
    width: 25%;
    left: 2%;
    min-height: 300px;
    position: sticky;
    border-radius: 4px;
    top: 30vh;
    transition: 0.3s ease-in-out;
}

.seemore__box {
    width: 100%;
}

.seemore {
    display: block;
    height: 40px;
    width: 30%;
    background: transparent;
    color: black;
    float: right;
    border: none;
    cursor: pointer;
}

.listing {
    width: 100%;
}

.addtocartM {
    display: none;
}

.product__info__left>h1 {
    margin-bottom: 10px;
}

.product__info__left>p {
    margin-bottom: 15px;
}

.product__info__right {
    color: gray;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: rgba(255, 255, 255, 0.18);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 25%;
    right: 2%;
    padding: 20px;
    min-height: 300px;
    height: 100%;
    position: sticky;
    border-radius: 4px;
    top: 30vh;
    transition: 0.3s ease-in-out;
}

.seemeasure {
    z-index: 2999;
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.seemeasure>img {
    max-width: 1000px;
    width: 95%;
}

.modalData {
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-content: center;
    animation: riseUp ease 1s;
}

@keyframes riseUp {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.variantHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.variantHolder>.variantButton {
    background: black;
    color: white;
    width: 50px;
    margin-right: 20px;
    font-size: 16px;
    height: 50px;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
}

.variantHolder>.variantButton:hover {
    background: white;
    color: black;
}

.disabled__size {
    position: relative;
    background: black;
    color: white;
    width: 50px;
    margin-right: 20px;
    font-size: 16px;
    height: 50px;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
    cursor: not-allowed;
}

.disabled__size::after {
    content: "";
    /* Empty content for the slash */
    position: absolute;
    top: 50%;
    /* Center vertically */
    left: 0;
    /* Start at the left */
    right: 0;
    /* End at the right */
    transform: translateY(-50%) rotate(-45deg);
    /* Center and tilt */
    height: 2px;
    /* Thickness of the slash */
    background-color: white;
    /* Red color for the slash */
}

.addtocart {
    padding: 10px;
    border-radius: 4px;
    display: block;
    font-size: 16px;
    height: 60px;
    width: 60%;
    border: 1px solid black;
    background: transparent;
    transition: 0.3s ease-in-out;
    opacity: 1;
}

.addtocart:hover {
    background: black;
    color: white;
}

.fade-out {
    animation: fadeOut ease 0.3s;
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

}

.fade-in {
    animation: fadeIn ease 0.3s;
}

@keyframes fadeIn {
    0% {

        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.seebtn {
    position: absolute;
    top: 35%;
    right: 12%;
    border: none;
    color: rgb(100, 100, 100);
    text-decoration: underline;
    background: transparent;
}

.seebtn1 {
    display: none;
}

.product__info__right>.size__option {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.product__info__right>.size__option>button {
    width: 100px;
}


.product__info__right>.cart__option {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.product__info__right .save {
    width: 20%;
    margin-right: 30px;
}

.product__info__right .addtocart {
    width: 80%;
}

.cart__option {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}


.accorddrop {
    background: transparent !important;
    box-shadow: none !important;
    transition: background-color 0.3s ease-in-out;
}


.accorddrop:hover {
    background-color: rgba(222, 222, 222, 0.5) !important;
}

.PAL {
    font-size: 20px;
    border-radius: 4px;
    background: rgba(56, 56, 56, 0.18);
    box-shadow: 4px 14px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}


@keyframes appear {
    from {
        opacity: 0;
        transform: translateX(100vw);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}




@media only screen and (max-width: 600px) {
    .variantHolder>button {
        font-size: 16px;
    }

    .cart__option {
        display: none;
    }

    .listing {
        display: none;
    }

    .product__detail {
        justify-content: center;
    }

    .seebtn1 {
        display: block;
        position: relative;
        top: -10px;
        right: 0;
        border: none;
        color: rgb(100, 100, 100);
        text-decoration: underline;
        background: transparent;
    }

    .product__mob {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: end;
        justify-content: space-between;
    }

    .product__mob>div>h1 {
        font-size: 20px;
    }

    .product__images {
        max-width: 100vw;
        overflow: scroll;
        flex-direction: row;
        justify-content: start;
        scroll-snap-type: x mandatory;
    }

    .product__images>img {
        scroll-snap-align: start;
        height: auto;
        width: 100%;
    }

    .product__info__left {
        min-height: 160px;
        position: absolute;

        left: auto;
        top: 93%;
        font-size: 10px;
        height: 160px;
        width: 80vw;
    }

    .addtocartM {
        display: block;
        height: 40px;
        border-radius: 4px;
        width: 100%;
        background: black;
        color: white;
    }

    .product__info__right {
        display: none;
    }

    .PAL {
        margin: 20px;
        margin-top: 150px;
        animation-range: entry cover 55%;
    }

}