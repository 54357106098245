.glide {
  position: relative;
}



.glide__slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
}
 /* General styling for media (images and videos) */
.media-image,
.media-video {
  padding: 40px;
  width: 70%;
  height: auto;
  max-height: 700px;  /* Restrict max height for both mobile and desktop */
  object-fit: cover;  /* Ensure content covers the container without distortion */
}

/* Media queries for responsive behavior */
@media (min-width: 768px) {
  .media-image,
  .media-video {
    max-width: 80%;   /* For larger screens, adjust the width */
    max-height: 70vh; /* Restrict max height to 80% of the viewport height */
  }
}

@media (min-width: 1024px) {
  .media-image,
  .media-video {
    max-width: 60%;   /* For larger desktops, restrict width */
    max-height: 80vh; /* Ensure it uses 80% of the viewport height */
  }
}

/* Adjustments for landscape orientation on mobile devices */
@media screen and (orientation: landscape) {
  .media-image,
  .media-video {
    width: auto;      /* Let width adjust automatically */
    height: 100%;     /* Ensure height takes up the available space */
    max-height: 80vh; /* Cap the height to prevent overflow */
  }
}

/* Adjustments for portrait orientation */
@media screen and (orientation: portrait) {
  .media-image,
  .media-video {
    width: 100%;      /* In portrait, prioritize width */
    height: auto;     /* Auto-adjust height to maintain aspect ratio */
  }
}


.oj {
  position: absolute;
  bottom: 20px; /* Distance from the bottom */
  left: 50%; /* Start centering from the left */
  transform: translate(-50%, 0); /* Center it horizontally */
  color: black; /* Change color to contrast with background */
  text-align: center; /* Centers the text */
  font-size: 2rem; /* Adjust size as needed */
  z-index: 10; /* Ensure the text is above other elements */
}

.glide__arrows {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  pointer-events: none; /* Makes sure arrows don't block other content from being clicked */
}

.glide__arrow {
  background-color: transparent;
  color: black;
  border: none;
  font-size: 2rem;
  padding: 2px;
  cursor: pointer;
  pointer-events: all; /* Re-enables click events on arrows */
}

.glide__arrow--left {
  margin-left: 10px;
}

.glide__arrow--right {
  margin-right: 10px;
}
