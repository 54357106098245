.faqpage {
}

.faqpage h1 {
    font-family: 'FuturaPTLight', sans-serif;
}


.faq__section1 {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.faq__section2 {
}

.faq__section2>.faq__quest {
    
    max-width: 1200px;
}

.faqaccord {
    font-size: 22px;
    background: transparent !important;
    box-shadow: none !important;
    transition: background-color 0.3s ease-in-out;
}

.questionmark {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
    margin-left: 3px;
    margin-right: 3px;
    display: inline;
}

.Hq {
    font-size: 20px;
}

.faqaccord:hover {
    background-color: rgba(222, 222, 222, 0.5) !important;
}

@media only screen and (max-width: 600px) {
    .faq__section2>.faq__quest {

        max-width: 400px;
    }

    .faqaccord {
        font-size: 16px;
    }

    .Hq {
        font-size: 15px;
    }
}