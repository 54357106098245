.sustainability__page {
  display: block;
  width: 100vw;
}


body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background: transparent;
}

.sustainability__page > .film {
  height: 111vh;
  margin-top: -300px;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.sustainability__page > .film > video {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.2;
}

.title {
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 250px;
  font-weight: 900;
  color: white;
}

.sustainability__section {
  max-width: 100vw;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sustainability__content {
  padding: 10px;
  width: 90%;
}

.sustainability__para {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sustainability__para > p {
  font-weight: 800;
  letter-spacing: 2px;
  line-height: 25px;
}

.sustainability__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sustainability__card {
  width: 550px;
  height: 550px;
  margin: 25px;
  background-color: rgb(248, 248, 248);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

#limitpro {
    transition: 0.3s ease-in-out;
}

.sustainability__card:hover #limitpro {
    filter: blur(10px) ;
}

#renew {
    transition: 0.3s ease-in-out;
}

.sustainability__card:hover #renew {
    filter: blur(10px) ;
}

#donate {
    transition: 0.3s ease-in-out;
}

.sustainability__card:hover #donate {
    filter: blur(10px) ;
}

.sustainability__card > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.sustainability__card > div {
position: absolute;
z-index: 999;
  width: 500px;
  height: 500px;
  opacity: 0;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
}

.sustainability__card > div:hover {
  opacity: 1;
}

@media only screen and (max-width: 1600px) {
  .sustainability__page > .film {
    height: 80vh;
    margin-top: -100px;
    width: 100vw;
    position: relative;
  }

  .title {
    font-size: 100px;
  }
  .sustainability__container {
    flex-direction: column;
  }

  .sustainability__card {
    padding: 15px;
  }
}
