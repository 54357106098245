.collections__page {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    padding: 10px;
    margin: 10px;
}

.collection__detail {
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    grid-auto-flow: dense;
    padding: 5px;
}

.collection__item {
    position: relative;
    width: 500px;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    
}

.collection__item>label {
    font-size: 20px;
    font-family: FuturaPTBook;
}

.collection__item>p {
    color: rgba(0, 0, 0, 0.7);
    font-size: 17px;
    font-family: Arial, Helvetica, sans-serif;
}

.collection__item > img {
    width: 100%;
    height: 100%;
}


@media only screen and (max-width: 2000px) {
    .collection__item {
        width: 100%;

    }
}

@media only screen and (max-width: 600px) {
    .collection__detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}