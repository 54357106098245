.insidethebox {
    width: 100vw;
    height: 100vh;
    box-shadow: inset black 0px 0px 60px -12px; 
}


.bottomGradient {
    position: fixed;
    bottom: 0;
    height: 300px;
    width: 100vw;
    background: linear-gradient(0deg, rgba(0,0,0,0.55), transparent);
}

.bottomGradient > p {
    position: fixed;
    right: 50px;
    bottom: 50px;
    font-size: 15px;
    color: white;
}

.bottomGradient > img {
    position: fixed;
    width: 50px;
    right: 50px;
    bottom: 10px;
}