.cart {
    padding-top: 100px;
    min-height: 100vh;
    min-width: 100vw;
    height: 100%;
    width: 100%;
    margin-top: 50px;
    display: flex;
    align-items: start;
    justify-content: center;
}

.cart__lines>label {
    text-align: center;
}

.cart__topbar {
    width: 90%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 25px;
}

.cart__topbar>label {
    width: 140px;
    font-weight: bold;
    color: gray;
}

.cart__holder {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    /*align-items: center;*/
}

.cart__lines {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cart__line {
    width: 90%;
    height: 250px;
    display: flex;
    align-items: center;
}

.cart__line:not(:first-of-type) {
    padding-top: 35px;
    border-top: 2px solid #d4d4d4;
}


.cart__line:not(:last-of-type) {
    padding-bottom: 35px;
    border-bottom: 2px solid #d4d4d4;
}

.cart__info>img {
    width: 100px;
}

.cart__line>img {
    display: none;
}


.cart__info {
    height: 65%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cart__info>label {
    width: 100px;
}

.cart__info>label {
    font-size: 20px;
}

.cart__info>label>span {
    font-family: Arial, sans-serif;
    font-weight: 400;
}

.quantity__controller {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    width: 90px;
}

.quantity__controller>label {
    text-align: center;
    width: 30px;
}

.quantity__controller>button {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    border: 1px solid rgb(171, 171, 171);
    padding: 0px;
    background: transparent;
    color: rgb(90, 90, 90);
    width: 35px;
    height: 35px;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
}

.quantity__controller>button:hover {
    background: rgb(24, 24, 24);
    color: rgb(200, 200, 200);
}

.payment__icon {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.75rem;
}

.payment__icon>li {
    list-style: none;
    color: #f53799;
}


.checkout__box {
    background: white;
    color: rgb(83, 83, 83);
    height: 100%;
    padding: 12px;
    margin-top: -6px;
    margin-bottom: -5px;
    width: 343px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}

.checkout__title {
    font-weight: 500;
    font-size: 22px;
    /*font-family: Nothing;*/
    color: black;
}

.subtotal {
    display: flex;
    justify-content: space-between;
}

.subtotal>span {
    display: flex;
}

.subtotal>span>p {
    font-family: Arial, Helvetica, sans-serif;
}

.shipping {
    display: flex;
    justify-content: space-between;
}

.total {
    display: flex;
    justify-content: space-between;
}

.total>span {
    display: flex;
}

.total>span>p {
    font-family: Arial, Helvetica, sans-serif;
}

.checkout__button {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkout__button>a {
    position: relative;
    height: 50px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: black;
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    /*font-family: Nothing;*/
}


@media only screen and (max-width: 600px) {
    .cart__holder {
        flex-direction: column-reverse;
    }

    .cart__topbar {
        display: none;
    }

    .cart__info {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
    }

    .cart__info>img {
        display: none;
    }

    .cart__info>label {
        margin-bottom: 8px;
    }

    .cart__line>img {
        margin-right: 30px;
        display: block;
        width: 160px;
    }

    .tit {
        font-weight: 900;
    }
}