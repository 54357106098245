header {
    position: fixed;
    top: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.header__drawer h2 {
    font-family: 'FuturaPTLight', sans-serif;
}

.header__panel {
    min-height: 90px;
    max-height: 120px;
    width: 100%;
    display: flex;
    padding-left: 20px;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    background: transparent;
    transition: 0.3s ease-in-out;
}

.logo__image {
    top: 200px;
    left: 15vw;
    width: 70vw;
    position: absolute;
    transition: 0.8s cubic-bezier(0.5, 0, 0, 1) 0s;
    filter: invert(100%) !important;
}

.logo__scroll {
    top: 33%;
    left: 20px;
    width: 200px;
    filter: invert(0%) !important;
}

.logo__text {
    font-family: BDB;
    font-size: 50px;
    text-decoration: none !important;
    color: black;
}

.logo>a {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
}

.header__right {
    max-width: 200px;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: end;
    top: 40px;
    right: 30px;
    color: black;
}

.nav__cart {
    cursor: pointer;
    position: relative;
}

.nav__cart>img {
    width: 30px;
}

.navbar-toggle {
    display: block;
    flex-direction: column;
    cursor: pointer;
    padding: 0.5rem;
    background: transparent;
    border: none;
    font-family: FuturaPTMedium;
}

.navbar-toggle span {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 3px 0;
}

.navbar-item a {
    text-decoration: none;
    color: #282828;
    font-weight: bold;
    transition: color 0.3s;
}

.navbar-item a:hover {
    color: gray;
}

.navbar-toggle {
    display: flex;
}

.navbar__container {
    position: absolute;

    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-links {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 220px;
    flex-direction: column;
    flex-wrap: wrap;
    position: absolute;
    top: 50px;
    border-radius: 4px;
    backdrop-filter: blur(8px);
    background-color: rgba(211, 211, 211, 0.5);
    width: 30vw;
    text-align: center;
    opacity: 0;
    transition: 0.4s ease-in-out;
}

.modal__container {
    top: 33px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    z-index: 1000;
}

.nav__links {
    position: relative;
    width: 400px;
    height: 200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: rgba(211, 211, 211, 0.5);
    color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    font-family: FuturaPTLight;
}
.nav__links>a {
    position: relative;
    text-decoration: none;
    font-size: 20px;
    color: black;
}

.nav__links>a:after {
    left: 0;
    top: 25px;
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    background-color: #747474;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.nav__links>a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.categories {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    font-family: FuturaPTLight;
}
.categories>a {
    position: relative;
    text-decoration: none;
    font-size: 20px;
    color: black;
}

.categories>a:after {
    left: 0;
    top: 25px;
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    background-color: #747474;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.categories>a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}



.ethics {
    font-size: 26px;
    color: black;
    font-family: BDB;
}

.floatbar__holder {
    top: 26px;
    left: 0;
    position: absolute;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

}


.floatbar {
    width: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.floatbar>button {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 0px;
    backdrop-filter: blur(8px);
    background-color: rgba(211, 211, 211, 0.5);
}



.menuHolder {
    width: 90vw;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    color: black;
}

.menuHolder>a {
    font-size: 15px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
    margin: 10px;
}

sup {
    font-size: 12px;
    color: rgb(157, 0, 0);
    font-family: BDB;
}

@media only screen and (min-width: 1000px) {
    .logo {
        z-index: 950;
    }
}


@media only screen and (max-width: 600px) {
    .floatbar__holder {
        top: 80px;
        justify-content: flex-start;
    }


    .floatbar {
        width: 40vw;

    }

    .floatbar>button {
        font-size: 10px;
    }

    .header__right {
        width: 150px;
    }

    .header__right>a {
        text-decoration: none;
        color: black;
    }

    .mobile__drawer {
        position: absolute;
        right: 10px;
    }
    .logo__image {
        top: 200px;
        left: 15vw;
        height: 8vh;
        width: auto;
        position: absolute;
        transition: 0.8s cubic-bezier(0.5, 0, 0, 1) 0s;
        filter: invert(100%) !important;
    }
    .logo {
        z-index:inherit;
    }
    .logo__scroll {
        top: 33%;
        left: 10px;
        height: 32px;
        width: auto;
        filter: invert(0%) !important;
    }
}