.privacypolicy {
    margin-top: 40px;
    min-height: 20vh;
    min-width: 100vw;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.privacy__scrollformore {

    text-align: center;
    position: sticky;
    top: 90%;
    right: 95%;
}

.privacypolicy__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: FuturaPTLight;
    max-width: 1000px;

    width: 100%;
}

.privacypolicy__content {
    margin-top: 10vh;
    padding: 20px;
}


.privacypolicy__content > p > * {
    margin: 30px 0px;
    padding: 4px;
    letter-spacing: 1.5px;
    border-radius: 2px;
}

.privacyblock h5{
    color: rgb(91, 91, 91);
}

.ourjourneyblock {
    display: flex;
    align-items: center;
}