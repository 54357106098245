.careerspage {
  padding-top: 100px;
  min-height: 110vh;
  height: 100%;
  background: #fdf0d5;
  display: block;
  width: 100vw;
}

.careerspage__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -300px;
  width: 100%;
  cursor: pointer;
}

.joinus__holder>img {
  width: 99vw;
  height: 100%;
}

.careerspage__text {
  font-family: TruenoUltLt;
  margin-top: 90px;
  width: 50%;
}

.careerspage__tagline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  width: 50%;
}

.box__holder {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.careerspage__box {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  height: 100%;
  width: 300px;
  background: #780000;
  border-radius: 10px;
  padding: 20px;
  color: #fdf0d5;
}

.emailusat {
  border-radius: 10px;
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emailusat>h3 {
  font-size: 30px;
}

.emailusat>h3>a {
  color: black;
}

.emailusat>h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-weight: 900;
}

.careerspage__tagline {
  font-family: BDB;
  font-size: 80px;
}

.TMOUS {
  text-align: center;
  text-transform: uppercase;
  font-size: 60px;
}


@media only screen and (max-width: 600px) {
  .joinus__holder>img {
    width: 2000px;
  }

  .box__holder {
    flex-direction: column;
  }

  .careerspage__tagline {
    font-size: 30px;
  }

}