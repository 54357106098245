.contactus {
    margin-top: 100px;
    height: 100%;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactus > h1 {
    font-size: 40px;
    margin-bottom: 20px;
}

.contactus__holder {
    width: 100%;
    padding: 10px;
}

.contactus__boxs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.glassy {
    width: 350px;
    height: 250px;
    background: rgba(255, 255, 255, 0.18);
    border-radius: 4px;
    box-shadow: 0 4px 30px rgba(109, 109, 109, 0.21);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin: 25px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    justify-content: space-between;
}

.top__box > h2 {
    margin-bottom: 10px;
}

.cta {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cta>button {
    margin-top: 8px;
    background: transparent;
    font-size: 15px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid lightgray;
    background: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center
}

.bookanappointment > .cta {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bookanappointment > .cta > button {
    margin-top: 8px;
    background: transparent;
    font-size: 15px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid lightgray;
    background: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

}

.bookanappointment {
    width: 350px;
    background: rgba(255, 255, 255, 0.18);
    border-radius: 4px;
    box-shadow: 0 4px 30px rgba(109, 109, 109, 0.21);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



@media only screen and (max-width: 2000px) {
    .contactus__boxs {
        justify-content: space-evenly;
    }
    .glassy {
        min-width: 200px;
        height: 170px;
    }
}

@media only screen and (max-width: 600px) {
    .contactus > h1 {
        text-align: center;
        letter-spacing: 1px;
        font-size: 30px;
        margin-bottom: 10px;
    }
    .contactus__holder {
        margin-top: 20px;
    }
    .contactus__boxs {
        flex-direction: column;
    }

    .glassy {
        height: 200px;
        width: 150px;
    }
    
    .bookanappointment {
        width: 200px;
    }

}