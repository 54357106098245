.search__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    
}

.search__components {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
}

.search__controller {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 300px;
    background: white;
    padding: 4px;
    border-radius: 2px;
}

.search__controller > input {
    width: 250px;
    height: 30px;
    padding: 0px 10px;
    border: none;
}

.search__controller > input:focus {
    outline: none;
}

.search__controller > input::placeholder {
    font-size: 18px;
}

.search__controller > button {
    font-size: 20px;
    font-family: BDB;
    background: transparent;
    color: black;
    border: none;
}

.search__results {
    max-height: 90vh;
    height: 100%;
    position: fixed;
    top: 20px;
    margin-top: 55px;
    height: 100%;
    background: white;
    border-radius: 2px;
    padding: 20px;
}

.search__card {
    color: black;
    display: flex;

    align-items: center;
}

.search__card>h5 {
    position: relative;
    text-decoration: none;
    font-size: 20px;
    color: black;
}

.search__card>h5:after {
    left: 0;
    top: 25px;
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    background-color: #747474;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.search__card>h5:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}