.collections__page {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    padding: 10px;
    margin: 10px;
}

.pal__detail {
    margin-top: 10px;
    max-width: 100vw;
    display: flex;
    overflow: scroll;
    flex-direction: row;
    justify-content: start;
    scroll-snap-type: x mandatory;
    grid-auto-flow: dense;
    padding: 5px;
}

.pal__item {
    width: 350px;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.pal__item>label {
    color: black;
    margin-top: 20px;
    font-size: 20px;
    font-family: FuturaPTBook;
}

.pal__item>p {
    color: rgba(0, 0, 0, 0.7);
    font-size: 17px;
    font-family: Arial, Helvetica, sans-serif;
}

.pal__item > img {
    width: 100%;
    height: 100%;
}




@media only screen and (max-width: 600px) {
    .pal__detail {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .pal__item > img {
        width: auto;
        height: 300px;
    }
    
    .pal__item {
        width: 150px;
        
    }
    .pal__item>label {
        font-size: 18px;
        white-space: nowrap;          /* Prevents text from wrapping to the next line */
  overflow: hidden;             /* Hides the overflowed text */
  text-overflow: ellipsis;      /* Adds the ellipsis (...) */
  width: 150px;
    }
}