.animatehome {
    animation: fadeLIn 2s ease-in-out;
}

.hero {
    position: relative;
    overflow: hidden;
    z-index: 1;
}


.hero__text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    transition: 0.4s ease-in-out;
    opacity: 0;
    position: absolute;
    right: 50px;
    z-index: 10;
    height: 40px;
    border-radius: 20px;
    transition: 2s ease-in-out;
    animation: floatJourney 2.5s ease-in-out;
    animation-iteration-count: infinite;

}

.hero__text>a {
    text-decoration: none;
    color: rgba(255, 0, 0, 0.5);
    font-size: 30px;

}

.hero__text>a:after {
    left: 0;
    top: 45px;
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    background-color: rgba(255, 0, 0, 0.5);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hero__text:hover a:after {
    background-size: 100%;
    transform: scaleX(1);
    transform-origin: bottom left;
}

.hero>video {
    transition: 1s ease all;
    width: 100%;
    filter: grayscale(100%) !important;
}

.hero:hover video {
    transform: scale(1.1);
    filter: grayscale(0) !important;
}

.hero > div >img {
    transition: 1s ease all;
    width: 50%;
    /*filter: grayscale(100%) !important;*/
}

.hero > div > img:hover {
    filter: grayscale(0) !important;
}


.mid__link {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    border-radius: 4px;
}

.mid__link>a {
    position: relative;
    text-decoration: none;
    font-size: 15px;
    color: black;
    padding: 10px;
}


.nav__link {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: rgba(211, 211, 211, 0.5);
    color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    backdrop-filter: blur(8px);
}

.nav__link>a {
    position: relative;
    text-decoration: none;
    font-size: 30px;
    color: black;
    padding: 10px;
}

.nav__link>a:after {
    left: 0;
    top: 50px;
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.nav__link>a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.hero__text {
    opacity: 1;
}

.landing__glass {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1000;
    backdrop-filter: blur(14px);
    background-color: rgba(144, 144, 144, 0.5);
}

.landing__glass > h1 {
    font-family: BDB;
    color: #202020;
    font-size: 50px;
}

.loading__page {
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeLOut 6s ease-in-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
         animation-fill-mode: forwards;
}

.loading__holder {
    font-family: monospace;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif;
    color: rgb(222, 222, 222);
}


.first__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
    width: 50px;
    overflow: hidden;
    overflow-y: hidden;
    box-shadow: inset 10px 10px 20px #000000;
    animation: marginFirst 4.5s linear;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
         animation-fill-mode: forwards;
}

.middle__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
    width: 50px;
    overflow: hidden;
    overflow-y: hidden;
    box-shadow: inset 10px 10px 20px #000000;
}

.last__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
    width: 50px;
    overflow: hidden;
    animation: marginLast 4.5s linear;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
         animation-fill-mode: forwards;
}

.first__container>span {
    position: relative;
    width: 50px;
    height: 50px;
    animation: countdown 3s;
}

.middle__container>span {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    animation: middleSwitch 4.5s ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
         animation-fill-mode: forwards;
}

.last__container>span {
    width: 50px;
    height: 50px;
}

.top-gradient {
    position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(255, 255, 255, 0.0));
    width: 50px;
    height: 10px;
}

.bottom-gradient {
    position: absolute;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.0) rgba(0, 0, 0, 0.9), );
    width: 50px;
    height: 10px;
}

@keyframes fade {
    from {
        opacity: 0;

    }
    to {
        opacity: 1;

    }
}

@keyframes floatJourney {
    0% {
        top: 85%;
    }

    50% {
        top: 86%;
    }

    100% {
        top: 85%;
    }
}

@keyframes marginFirst {
    0% {
        transform: translateX(-25px);
    }

    90% {

        transform: translateX(-25px);
    }

    100% {

        transform: translateX(0px);

    }
}

@keyframes marginLast {
    0% {
        transform: translateX(25px);
    }

    90% {
        transform: translateX(25px);
    }

    100% {
        transform: translateX(0px);

    }
}

@keyframes middleSwitch {
    0% {
        transform: translateY(-100%);
    }

    60% {
        transform: translateY(-100%);
    }

    70% {
        transform: translateY(-150%);
    }

    100% {
        transform: translateY(-200%);

    }
}




@keyframes countdown {
    0% {
        transform: translateY(100%);
    }

    90% {
        transform: translateY(-2700%);
    }

    100% {
        transform: translateY(-2700%);
    }
}

@keyframes fadeLIn {
    0% {
        opacity: 0;
    }

    95% {
        opacity: 0;
    }

    100% {
        opacity: 1;

    }
}

@keyframes fadeLOut {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;

    }
}

@media only screen and (max-width: 600px) {
    .hero__text>a {
        font-size: 25px;
    }
    .hero>div>img {
        transition: 1s ease all;
        width: 100%;
        /*filter: grayscale(100%) !important;*/
    }
    
    .landing__glass > h1 {
        font-family: BDB;
        color: #202020;
        font-size: 25px;
    }

    .landing__glass > img {
        width: 70px;
    }
    
}