.register__page {
    margin-top: 100px;

}

.register__page>form {
    width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.18);
    box-shadow: 0 4px 20px rgba(109, 109, 109, 0.21);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 100px;
    transition: 1.5s ease-in-out;
}

.register__page>form>button {
    width: 100%;
    padding: 14px;
    margin-bottom: 20px;
    color: white;
    background: black;
    border: none;
    cursor: pointer;
}

.register__page>form>p {
    font-family: BDB;
    font-size: 30px;
    transition: 0.3s ease-in-out;

}

.names__container>label>input {
    border: 1px solid rgb(221, 221, 221);
    padding: 6px 2px;

}

.inputs__container>label>input {
    border: 1px solid rgb(221, 221, 221);
    padding: 6px 2px;
   
}

.input__class {
    border: 1px solid rgb(221, 221, 221);
    padding: 6px 2px;
    width: 90%;
}

.showpass {
    width: 30px;
    margin: 5px;
    border: none;
    color: black;
}

.inputs__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

input[type=checkbox] {
    accent-color: black;
}

.inputs__container>label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.names__container {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
}

.names__container>label {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.newsletter {
    font-weight: bold;
    cursor: pointer;
}

.newsletter>input {
    margin-right: 10px;
}



.loader>img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }

    25% {
        -moz-transform: rotate(90deg);
    }

    50% {
        -moz-transform: rotate(100deg);
    }

    75% {
        -moz-transform: rotate(350deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    25% {
        -webkit-transform: rotate(90deg);
    }

    50% {
        -webkit-transform: rotate(100deg);
    }

    75% {
        -webkit-transform: rotate(350deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    25% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    50% {
        -webkit-transform: rotate(100deg);
        transform: rotate(100deg);
    }

    75% {
        -webkit-transform: rotate(270deg);
        transform: rotate(350deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 600px) {
    .register__page {
        margin-top: 100px;
    }
    .register__page>form {
        width: 340px;
    }
    .register__page>form>p {
        font-size: 20px;
    }
}